<template>
  <div class="bu-ju-3">
    <div class="top">
      <nav-header title="消息管理">
        <template #right>
          <a href="javascript:" @click="setReadAll()">全部已读</a>
        </template>
      </nav-header>
    </div>
    <div class="body">
      <xia-la-load ref="xiaLa" @load="getLoad">
        <div class="message-list"
             v-for="(vo,index) in dataList"
             :key="vo.id"
             @click="setRead(index)"
        >
          <div class="message-title">
            <van-badge
                dot
                position='top-left'
                :class="{yue_di:vo.read_status}"
            >
              <div class="van-ellipsis">{{ vo.title }}</div>
            </van-badge>
          </div>
          <div class="message-content">
            {{ vo.content }}
          </div>
          <div class="message-create-time">
            <van-row>
              <van-col :span="12">时间：{{ vo.create_time }}</van-col>
              <van-col :span="12" style="text-align: right">
                <message-href :info="vo.url"/>
              </van-col>
            </van-row>
          </div>
        </div>
      </xia-la-load>

    </div>
    <div class="footer">
      <footer-nav/>
    </div>
  </div>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import FooterNav from "../open/footerNav";
import XiaLaLoad from "../../components/list/XiaLaLoad";
import {ref, onMounted} from 'vue'
import http from "../../api/http";
import XEUtils from "xe-utils";
import moment from "moment";
import MessageHref from "@/views/user/public/messageHref";

export default {
  name: 'MessageList',
  components: {MessageHref, XiaLaLoad, FooterNav, NavHeader},
  mixins: [],
  data() {
    return {
      where: {},
      dataList: []
    }
  },

  mounted() {
  },
  methods: {
    getLoad(page) {
      this.where.page = page
      http.get('/gzhphp/user/getMessage', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
          item = item.map(n => {
            n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD HH:mm')
            n.read_status = XEUtils.toInteger(n.read_time) > 0 ? true : false //false 未读
            return n
          })
          if (page === 1) this.dataList = item
          else this.dataList = [...this.dataList, ...item]
        })
      })
    },
    setReadAll() {
      http.post('/gzhphp/user/setMessageRead', {type: 'all'}).then(da => {
        if (da.code === 1) {
          this.$refs.xiaLa.resetData()
        }
      })
    },
    setRead(index) {

      if(this.dataList[index].read_status){
        return
      }
      http.post('/gzhphp/user/setMessageRead', {id: this.dataList[index].id}).then(da => {
        if (da.code === 1) {
          this.dataList[index].read_status = true
        }
      })
    }
  },
  watch: {},
  computed: {
    getDataList() {
    }
  }
}
</script>


<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.message-list {
  background-color: @white;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 17px 14px;
  box-shadow: @yin-ying;

  .message-title {
    font-size: 16px;

    .biao-qian {
      font-size: 14px;
      background-color: @qian-lan;
      padding: 3px 8px;
      color: @blue;
      border-radius: 5px;
    }
  }

  .message-content {
    margin-bottom: 8px;
    color: @gray-6;
  }

  .message-create-time {
    color: @gray-6;
  }
}

.yue_di /deep/ .van-badge {
  display: none;
}
</style>
