<template>
  <a href="" @click.prevent="onClick" v-if="isShow">
    {{ info.val }}
  </a>
</template>

<script>
import XEUtils from "xe-utils";

export default {
  name: 'messageHref',
  props: {
    info: Object
  },
  methods: {
    onClick() {
      if (this.info.type === 'vue') {
        this.$router.push(this.info.url)
        return
      }
      location.href = this.info.url
    }
  },
  computed: {
    isShow() {
      return XEUtils.isObject(this.info)
    }
  }
}
</script>
<style scoped>

</style>
