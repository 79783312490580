<template>
  <van-list
      class="xia-la-jia-zai"
      v-model:loading="getLoading"
      :finished="finished"
      :finished-text="finishedText"
      @load="getData"
      style="padding-top:0"
  >
    <slot></slot>
  </van-list>
</template>

<script>
export default {
  name: 'XiaLaLoad',
}
</script>
<script setup>
import {useContext, ref, defineEmit, nextTick, defineProps} from 'vue'

const ext = useContext()
const emit = defineEmit(['load'])
let dataList = ref([]) //加载的数据列表
let getLoading = ref(true) //false = 加载中  true等待加载
let finished = ref(false) //true = 加载完毕
let dataPage = 1 //第几页
let props = defineProps({
  finishedText: {
    type: String,
    default() {
      return '没有更多了'
    }
  }
})

//设置加载成功
function setLoadSuccess(data) {
  if (data.code === 1) {
    dataList.value = dataList.value.concat(data.data.data)
    getLoading.value = false
    dataPage++
  }

  //计算 是否加载完毕
  if (dataList.value.length >= data.data.total || data.code != 1) {
    finished.value = true
  }
  return dataList.value
}


function setLoadSuccess2(data) {
  return new Promise((s) => {
    s({
      page: dataPage,
      item: data.data.data,
      all: dataList.value,
      total:data.data.total
    })
    nextTick(() => {
      setLoadSuccess(data)
    })

  })

}

//清空数据，分页还原到1
function resetData() {
  dataPage = 1
  getLoading.value = false
  getData()
}

function getData() {
  if (dataPage <= 1) {
    dataList.value = []
  }

  emit('load', dataPage)
}

function editData(data) {
  dataList.value = data
}

/**
 *安案例参考
 <xia-la-load ref="xiaLa" @load="getLoad"> //…… </xia-la-load>
 data() {
    return {
      where: {},
      dataList: []
    }
  },
 methods: {
     resetList(){
          this.$refs.xiaLa.resetData()
    },
    getLoad(page) {
      this.where.page = page
      http.get('/gzhphp/user/getMessage', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
           //vue2
            item = item.map(n => n) //修改数据
              if (page === 1) this.dataList = item
              else this.dataList = [...this.dataList, ...item]
            })
            //vue3
            xiaLa.value.setLoadSuccess2(da).then(({page, item}) => {
              if (page === 1) dataList.value = item
              else dataList.value = [...dataList.value, ...item]
            })
        })
    }
  },

 */
ext.expose({
  setLoadSuccess,
  resetData,
  setLoadSuccess2,
  editData //同步修改数据
})

</script>

<style scoped lang="less">
.xia-la-jia-zai {
  border-bottom: 0;
  background-color: transparent;

}
</style>
